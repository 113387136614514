import React from 'react';
import Container from '@material-ui/core/Container';
import Header from './Header';
import Footer from './Footer';
import { Helmet } from "react-helmet";

import { library } from '@fortawesome/fontawesome-svg-core'
import { faGhost, faScroll, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { fab, faAmazon} from '@fortawesome/free-brands-svg-icons';
library.add(fab, faGhost, faScroll, faAmazon, faEnvelope);

// import { ApolloClient } from 'apollo-client';
// import { HttpLink } from 'apollo-link-http';
// import { InMemoryCache } from 'apollo-cache-inmemory';

// import { ApolloProvider } from 'react-apollo';

// const GRAPHCMS_API = 'https://api-us-west-2.graphcms.com/v2/ck8m9k3cx10iu01xm1gst2ed6/master';


// const client = new ApolloClient({
//   link: new HttpLink({ uri: GRAPHCMS_API }),
//   cache: new InMemoryCache(),
// });

export default function Layout(props) {
  return(
    <React.Fragment>
      <Helmet>
        {/* <!-- Begin Constant Contact Active Forms --> */}
        <script> var _ctct_m = "0bc451feafe96ec66aefb43c8576c26b"; </script>
        <script id="signupScript" src="//static.ctctcdn.com/js/signup-form-widget/current/signup-form-widget.min.js" async defer></script>
        {/* <!-- End Constant Contact Active Forms --> */}
      </Helmet>
      <Container>
        <Header />
        <main>
          {props.children}
        </main>
        <Footer />
      </Container>
    </React.Fragment>
)};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
