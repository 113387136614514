import React from 'react';
import { Link as RouterLink } from 'gatsby';
// import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import Icon from '@material-ui/core/Icon';
import HomeIcon from '@material-ui/icons/Home';
import CreateIcon from '@material-ui/icons/Create';
import PlaceIcon from '@material-ui/icons/Place';
import PersonIcon from '@material-ui/icons/Person';

import logo from '../images/spooktlogo2.png';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbarTitle: {
    flex: 1,
  },
  toolbarSecondary: {
    justifyContent: 'space-between',
    overflowX: 'auto',
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
  },
  navButton: {
    fontFamily: 'NightmarePills',
  },
}));

const sections = [
  { title: 'Home', url: '/', icon:'HomeIcon' },
  { title: 'Places', url: '/places', icon:'PlaceIcon' },
  { title: 'Lore', url: '/lore', icon:'ScrollIcon' },
  { title: 'People', url: '/people', icon:'PersonIcon' },
  { title: 'About', url: '/about', icon: null }
];

function renderIcon(icon) {
  return;
  switch(icon) {
    case 'HomeIcon':
      return <HomeIcon fontSize="small" />;
    case 'ScrollIcon':
      return <i class="fas fa-scroll"></i>;
    case 'PlaceIcon':
      return <PlaceIcon fontSize="small" />;
    case 'PersonIcon':
      return <PersonIcon fontSize="small" />;
    default:
      return;
  }
}

export default function Header () {
  const classes = useStyles();

  return (
    <header>
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/" >
          <h1><img alt="spookt" src={logo} /></h1>
        </RouterLink>
      </Toolbar>
      <Toolbar component="nav" className={classes.toolbarSecondary} >
        {sections.map((section) => (
          <Button 
            size="small" 
            //color="primary" 
            component={RouterLink}
            to={section.url}
            className={classes.navButton}
            key={section.title}
          >
            {renderIcon(section.icon)}
            {section.title}
          </Button>
        ))}
      </Toolbar>
    </header>
  );
}