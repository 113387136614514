import React from 'react';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MuiOutboundLink from './MuiOutboundLink';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';


const useStyles = makeStyles((theme) => ({
  followLabel: {
    fontSize: '20px',
    color: 'textSecondary',
    fontFamily: 'NightmarePills, Roboto, Helvetica, Arial, sans-serif',
  },
  socialLink: {
    fontSize: '20px',
    marginLeft: '10px'
  },
  mailingList: {
    fontSize: '16px'
  }
}));


function Copyright() {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Grid container style={{marginTop: '16px', marginBottom: '8px'}}>
        <Grid item xs={12}  align="center" >
          <Typography className={classes.followLabel} >
            Haunt us:
          
          <Link component={MuiOutboundLink} href='https://www.facebook.com/spooktcom' target="_blank" rel="noopener" className={classes.socialLink} >
            <FontAwesomeIcon icon={['fab', 'facebook-f']}  />
          </Link>
          <Link component={MuiOutboundLink} href='https://www.instagram.com/spookt_com/' target="_blank" rel="noopener" className={classes.socialLink} >
            <FontAwesomeIcon icon={['fab', 'instagram']} />
          </Link>
          <Link component={MuiOutboundLink} href='https://twitter.com/spookt_com' target="_blank" rel="noopener" className={classes.socialLink} >
            <FontAwesomeIcon icon={['fab', 'twitter']} />
          </Link>
          <Link component={MuiOutboundLink} href='https://www.pinterest.com/spookt_com/' target="_blank" rel="noopener" className={classes.socialLink} >
            <FontAwesomeIcon icon={['fab', 'pinterest']} />
          </Link>
          </Typography>
        </Grid>
        <Grid item xs={12} align='center'>
          <Link component={MuiOutboundLink} href='https://lp.constantcontactpages.com/su/JdyYv1d/spookt' target="_blank" rel="noopener" className={classes.mailingList} >
            Join our mailing list <FontAwesomeIcon icon='envelope' /> 
          </Link>
        </Grid>
      </Grid>
      <Typography variant="body2" color="textSecondary" align="center" style={{marginTop: '18px'}}>
        <Link href="/privacy">
          Privacy Policy
        </Link>{' | '}
        <Link href="/terms">
          Terms of Use
        </Link>{' '}
      </Typography>
      <Typography variant="body2" color="textSecondary" align="center" style={{marginBottom: '16px'}}>
        {'Copyright © '}
        <Link color="inherit" href="https://spookt.com">
          Spookt
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    </React.Fragment>
  );
}

export default () => (
    <Copyright></Copyright>
);